<template>
  <div class="container ">
    <h2 class="text-center my-4">
      <strong>PROMOTION AND OFFER ECART.COM</strong>
    </h2>

    <b-tabs content-class="mt-3" v-model="tabIndex">
      <b-tab title="NATIONAL SHIPPING">
        <div>
          <div class="text-right">
            <p>
              <strong>UPDATE VERSION:</strong>
              <br>
              <strong>Monterrey, Nuevo Le&oacute;n, December 10, 2020.</strong>
            </p>
          </div>

          <p class="text-center f-16"><strong>&ldquo;NATIONAL SHIPPING 2020&rdquo;</strong></p>

          <p><span style="font-weight: 400;">The following terms and conditions will rule the PROMOTION of
            </span><strong>"NATIONAL SHIPPING 2020"</strong><span style="font-weight: 400;"> that </span><strong>ECART
              TECHNOLOGIES S.A. DE C.V.</strong><span style="font-weight: 400;"> (hereinafter called as ¨ecart¨ )
              will offer to the users who wish to use the digital platform of </span><a href="http://www.ecart.com"
              target="_blank"><span style="font-weight: 400;">www.ecart.com</span></a><span
              style="font-weight: 400;">.</span></p>
          <p><strong>Ecart Technologies S.A. de C.V.</strong><span style="font-weight: 400;"> it is located at
            </span><strong>Belisario Dom&iacute;nguez number 2470, 3rd floor, Colonia Obispado, Monterrey, Nuevo
              Le&oacute;n, C.P. 64060.</strong></p>
          <p><span style="font-weight: 400;">BY USING THE WEBSITE, YOU ACCEPT AND AGREE TO THE TERMS AND CONDITIONS OF
              PROMOTIONS AND OFFERS REGARDING YOUR USE OF THE WEBSITE. If you are not satisfied with these terms and
              conditions, please do not use the ecart.com website. If you have any questions respect to the terms and
              conditions, please write an email to </span><a href="mailto:support@ecart.com"><span
                style="font-weight: 400;">support@ecart.com</span></a><span style="font-weight: 400;">.</span></p>
          <p class="text-center f-16"><strong>APPLICABLE CONCEPTS</strong></p>
          <ol class="decimal">
            <li style="font-weight: 400;"><span style="font-weight: 400;">SELLER/USER: It is the person who has
                registered on the ecart.com platform to distribute, market and sell the items within the platform, with
                the benefit that anyone can view their items and easily purchase them.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">CLIENT/COSTUMER: It is the person who joins to
                ecart.com to buy online from the stores available on the website any item that is to their
                interest.</span></li>
          </ol>
          <p class="text-center f-16 "><strong>PROMOTION SPECIFICATION &ldquo;NATIONAL SHIPPING 2020&rdquo;</strong></p>
          <p><span style="font-weight: 400;">ECART provide to the sellers registered in Ecart for the sale and marketing
              of their articles, products and/or merchandise 20 (twenty) available and free national guides per store
              from August 01 (one), 2020 (two thousand and twenty) on&nbsp;the understanding that&nbsp;in the generation
              of the guide number 21 (twenty-one) shipping to the destination stipulated in the purchase request by the
              client on the ecart.com platform will be charged.</span></p>
          <p class="text-center f-16 text-underline"><strong>THIS PROMOTION WILL BE REGULATED BY THE FOLLOWING
              SPECIFICATIONS:</strong>
          </p>

          <ol class="decimal">

            <li style="font-weight: 400;"><span style="font-weight: 400;">PROMOTION NATIONAL SHIPPING 2020: Candidates
                to get the promotion and enjoy it benefits:&nbsp;</span>
              <ol>
                <li style="font-weight: 400;"><span style="font-weight: 400;">The seller who has registered stores for
                    the
                    marketing and sale of their products and/ or merchandise on the Ecart platform.</span></li>
              </ol>
              <br>
            </li>

            <li style="font-weight: 400;"><span style="font-weight: 400;">GENERAL GUIDELINES FOR NATIONAL SHIPPING 2020:
                The seller will obtain the benefits mentioned in the offer "NATIONAL SHIPPING 2020" according to the
                following guidelines:</span>
              <ol>
                <li style="font-weight: 400;"><span style="font-weight: 400;">The promotion of free national guides is
                    only
                    applicable for the first 20 (twenty) national guides, from guide number 21 (twenty-one) it will be
                    charged according to the destination stipulated in the client's purchase request, with the
                    understanding
                    that the price may vary according to the destination to which the customer wishes to send the
                    purchased
                    product.</span></li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">The promotion will be applicable to the
                    customer who purchases products with a minimum value of $449.00 (four hundred and forty-nine pesos
                    00/100 MXN). With the understanding that said amount may be converted to the following
                    currencies:&nbsp;
                    USD, EUR, and COP.</span></li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">The promotion applies only to destinations
                    within the area of </span><span style="font-weight: 400;">​​</span><span
                    style="font-weight: 400;">the
                    Mexican Republic.</span></li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">The promotion will only be for orders no
                    more
                    than 2.20462 volumetric pounds through the following packages: Sendex, Quiken and Redpack.</span>
                </li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">The promotion will not be
                    transferable.</span>
                </li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">The promotion will not be accumulated with
                    other offers.</span></li>
              </ol>
              <br>
            </li>
            <li style="font-weight: 400;"><strong>VALIDITY OF THE PROMOTION.</strong><span style="font-weight: 400;">
                The procedure to validate the "NATIONAL SHIPPING 2020" promotion is as follows:</span>
              <ol>
                <li style="font-weight: 400;"><span style="font-weight: 400;">At the time of generating the guide and
                    introducing the national shipping address, the cost of the shipping price will be deducted, only for
                    the
                    first 20 (twenty) national guides, with the understanding that in the guide number 21 (twenty-one)
                    it
                    will be charged.&nbsp;</span></li>
              </ol>
              <br>
            </li>
          </ol>

          <p><span style="font-weight: 400;">By participating in the promotion </span><strong>"NATIONAL SHIPPING
              2020"</strong><span style="font-weight: 400;">, the Seller affirms that he has read and understood these
              Terms and Conditions, accepting the veracity, legitimacy and guidelines which the promotion will be ruled
              consciously, reviewed satisfactorily the information described above, which is considered adequate and
              sufficient.</span></p>

        </div>
      </b-tab>
      <b-tab title="INTERNATIONAL SHIPPING">
        <div>
          <p class="text-right">
            <strong>PUBLICATION DATE:</strong><strong><br></strong>
            <strong>Monterrey, Nuevo
              Le&oacute;n, June 01, 2020.</strong></p>
          <p class="f-16 text-center"><strong>&ldquo;INTERNATIONAL SHIPPING&rdquo;</strong></p>
          <p><span style="font-weight: 400;">The following terms and conditions will rule the PROMOTION of
            </span><strong>"INTERNATIONAL SHIPPING"</strong><span style="font-weight: 400;"> that
            </span><strong>ECART
              TECHNOLOGIES S.A. DE C.V.</strong><span style="font-weight: 400;"> (hereinafter called as
              &uml;ecart&uml;) will offer to the users who want to use the digital platform of </span><a
              href="http://www.ecart.com"><span style="font-weight: 400;">www.ecart.com</span></a><span
              style="font-weight: 400;">.</span></p>
          <p><strong>Ecart Technologies S.A. de C.V.</strong><span style="font-weight: 400;"> It is located at
            </span><strong>2470 Belisario Dominguez, 3rd floor, Monterrey, Nuevo Le&oacute;n, Mexico,
              64060.</strong>
          </p>
          <p><span style="font-weight: 400;">BY USING THE WEBSITE, YOU ACCEPT AND AGREE THE TERMS AND CONDITIONS OF
              PROMOTIONS AND OFFERS REGARDING YOUR USE OF THE WEBSITE. If you are not satisfied with these terms
              and
              conditions, please do not use the ecart.com website. If you have any questions regarding the terms
              and
              conditions, please write an email to </span><a href="mailto:support@ecart.com"><span
                style="font-weight: 400;">support@ecart.com</span></a><span style="font-weight: 400;">.</span>
          </p>
          <p class="text-center f-16"><strong>APPLICABLE CONCEPTS</strong></p>
          <ol class="decimal">
            <li><strong>CLIENT:<span style="font-weight: 400;"> It is the person who joins ecart.com to buy online
                  from
                  the stores available on the website any item that is to their interest.</span></strong></li>

            <li><strong>INTERNATIONAL SHIPPING:<span style="font-weight: 400;"> The products will be sent to any
                  country
                  except the Mexican Republic.</span></strong></li>
          </ol>

          <p class="text-center f-16"><strong>PROMOTION SPECIFICATION &ldquo;INTERNATIONAL SHIPPING&rdquo;</strong></p>
          <p><strong>Ecart </strong><span style="font-weight: 400;">offers its customers a preferential rate of $10.99
              USD* (ten dollars and ninety-nine cents USD) for each merchandise and/or product purchased within
              the
              ecart.com platform, in which the shipment will be destined for any country except the Mexican
              Republic.</span></p>
          <p class="text-center f-16 text-underline"><strong>THIS PROMOTION WILL BE REGULATED BY THE FOLLOWING
              SPECIFICATIONS:</strong></p>
          <ol class="decimal">
            <li style="font-weight: 400;"><strong>INTERNATIONAL SHIPPING PROMOTION:</strong><span
                style="font-weight: 400;">
                Candidates to get the promotion and enjoy it
                benefits:&nbsp;</span>
            </li>
            <ol>
              <li>
                <strong>
                  <span style="font-weight: 400;">The merchandise must weigh less than 2 (two)
                    kilograms.</span>
                </strong>
              </li>

              <li><span style="font-weight: 400;">The Client registered on the ecart.com platform who makes a purchase
                  and
                  the destination is different from Mexico, that is, an international shipment.</span></li>
            </ol>
            <br>
            <p class="ml-3"><span style="font-weight: 400;">They are not candidates to enjoy the promotion:</span></p>
            <ol>
              <li><strong><span style="font-weight: 400;">The merchandise and/or products acquired destined for the
                    Mexican Republic.</span></strong></li>

              <li><span style="font-weight: 400;">Merchandise and/or products that weigh more than 2 (two)
                  kilograms.</span></li>

              <li><span style="font-weight: 400;">The promotion will not be transferable.</span></li>

              <li><span style="font-weight: 400;">The promotion will not be accuolted with other offers.</span></li>
            </ol>
            <br>
            <li style="font-weight: 400;">
              <strong>VALIDITY OF THE PROMOTION.</strong><span style="font-weight: 400;"> The
                procedure to validate the </span><strong>"INTERNATIONAL
                SHIPPING"
              </strong><span style="font-weight: 400;">promotion is as follows:</span><span
                style="font-weight: 400;">&nbsp;</span>
              <br>
              <ol>
                <li><strong><span style="font-weight: 400;">When generating the purchase within the ecart.com platform
                      and
                      entering the international delivery address, the special rate of $10.99 USD* (ten dollars
                      and
                      ninety-nine cents USD) will be automatically activated in the purchase order and you can
                      proceed
                      with the payment.</span><span style="font-weight: 400;"></span></strong></li>
              </ol>
            </li>

            <li style="font-weight: 400;"><strong>PACKAGE DELIVERY.</strong><span style="font-weight: 400;"> The
                applicable package for the promotion of the special rate of $10.99 USD* (ten dollars and
                ninety-nine
                cents USD)</span> <strong> for international shipments will be only FEDEX.</strong></li>
          </ol>
          <p><span style="font-weight: 400;">By participating in the promotion </span><strong>"INTERNATIONAL
              SHIPPING"</strong><span style="font-weight: 400;">, the Client affirms that has read and understood
              these Terms and Conditions, accepting the veracity, legitimacy and guidelines which the promotion
              will
              be ruled consciously, reviewed satisfactorily the information described above, which is considered
              adequate and sufficient.</span></p>
        </div>
      </b-tab>
      <b-tab title="HOT PROMO">
        <p class="text-center f-16"><strong>&ldquo;HOT PROMO&rdquo;</strong></p>
        <p><span style="font-weight: 400;">The following terms and conditions will rule the advertising CAMPAIGN
            of&nbsp;</span><strong>"HOT PROMO"</strong><span
            style="font-weight: 400;">&nbsp;that&nbsp;</span><strong>ECART TECHNOLOGIES, S.A. DE
            C.V.&nbsp;</strong><span style="font-weight: 400;">&nbsp;(hereinafter referred as &rdquo;ECART&rdquo;) will
            offer to the users who want to use the digital platform of&nbsp;</span><a target="_blank" href="http://www.ecart.com/"><span
              style="font-weight: 400;">www.ecart.com</span></a><span style="font-weight: 400;"> (hereinafter the
            &ldquo;ECART Website&rdquo;).</span></p><br>
        <p><strong>ECART</strong><span style="font-weight: 400;"> is located at&nbsp;</span><strong>2470 Belisario
            Dominguez, Obispado, 3rd floor, Monterrey, Nuevo Le&oacute;n, Mexico, 64060.</strong></p><br>
        <p><span style="font-weight: 400;">BY PARTICIPING IN THIS CAMPAIGN, YOU ACCEPT AND AGREE THE TERMS AND
            CONDITIONS OF PROMOTIONS AND OFFERS REGARDING YOUR USE OF THE ECART WEBSITE. SUCH TERMS AND CONDITIONS ARE
            FINAL AND BINDING FOR THE USERS / END CONSUMERS WITH REGARD TO THE USE OF THE ECART WEBSITE. If you are not
            satisfied with these terms and conditions, please do not participate in the campaign &ldquo;HOT PROMO&rdquo;
            of the ECART Website. If you have any question regarding these terms and conditions, please write an email
            to&nbsp;</span><a href="mailto:support@ecart.com"><span
              style="font-weight: 400;">support@ecart.com</span></a><span style="font-weight: 400;">.</span></p><br>
        <p><strong>FIRST. </strong><span style="font-weight: 400;">The sole objective of the &ldquo;HOT PROMO&rdquo;
            campaign is to boost the sales of your store.&nbsp;</span></p><br>
        <p><strong>SECOND.</strong><span style="font-weight: 400;"> You recognize and accept that ECART is not
            responsible of the amount of discount that user decide to add to the purchase.</span></p><br>
        <p><strong>THIRD. </strong><span style="font-weight: 400;">You recognize and accept that ECART is not
            responsible of paying you the total amount discounted from the discounts added by the user. Therefore, in
            this act, you totally release ECART from any responsibility.</span></p><br>
        <p><strong>FORTH. </strong><span style="font-weight: 400;">The &ldquo;HOT PROMO&rdquo; campaign is valid only
            for those orders that are placed through the ECART Website.</span></p><br>
        <p><strong>FIFTH.</strong><span style="font-weight: 400;"> The campaign will be valid only (i) from May
            23</span><span style="font-weight: 400;">rd</span><span style="font-weight: 400;">, 2021 at 12:01 a.m. as of
            May 31</span><span style="font-weight: 400;">st</span><span style="font-weight: 400;">, 2021 at 11:59 p.m.,
            or (ii) while supplies last, whichever comes first.</span></p><br>
        <p><strong>SIXTH. </strong><span style="font-weight: 400;">The discounts of the offered products are not
            interchangeable and cannot be transferred.</span></p><br>
        <p><span style="font-weight: 400;">By participating in the campaign&nbsp;</span><strong>"HOT
            PROMO"</strong><span style="font-weight: 400;">, you affirm that you have read and understood these Terms
            and Conditions and are agree with them, accepting the veracity, legitimacy and guidelines which the campaign
            will be ruled consciously; you have reviewed satisfactorily the information described above, which is
            considered adequate and sufficient.</span></p><br>
        <p>&nbsp;</p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabIndex: 0,
      }
    },
    beforeMount() {
      this.tabIndex = Number(this.$route.query.tab) || 0;
    }
  }
</script>